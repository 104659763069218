import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Popover } from '@douyinfe/semi-ui';
import { Icon } from '@/components';
import { useTranslation } from 'react-i18next';
import './VersionSwitch.scss';
import classNames from 'classnames';
import { useAppDispatch } from '@/store/hooks';
import { dataCloudActions } from '@/store/reducers/dataCloudReducer';

interface VersionSwitch {
  version: string
}

const RenderButton = ({
  children,
  active,
  onClick,
  isNew = false
}: {children: React.ReactNode, active: boolean, onClick: React.MouseEventHandler<HTMLButtonElement>, isNew?: boolean}): React.ReactElement => (
  <Button
    className={classNames('btn-version')}
    theme="borderless"
    onClick={onClick}
  >
    {children}

    {isNew ? <span className="new">new</span> : null}

    {active ? <Icon icon="common/check" className="ml-auto w-[12px] h-[12px]" /> : null}
  </Button>
);

export default function VersionSwitch({ version }: VersionSwitch): React.ReactElement {
  const { t } = useTranslation();
  const location = useLocation();
  const nav = useNavigate();
  const popoverRef = useRef<Popover>(null);

  const dispatch = useAppDispatch();

  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
  const popConfirmedKey = 'datacloud_v2_popconfirmed';
  const [popConfirmVisible, setPopConfirmVisible] = useState(false);
  useEffect(() => {
    if (localStorage.getItem(popConfirmedKey) !== 'true') {
      setPopConfirmVisible(true);
    }
  }, []);

  const renderPopover = () => (
    <div className="relative justify-center">
      <div
        className="w-[276px] h-[140px] z-50 px-4 -align-start rounded-lg flex flex-col justify-evenly text-sm text-[Roboto] font-semibold"
        style={{
          boxSizing: 'content-box'
        }}
      >
        <div className="w-[244px] text-white">{t('dataCloud.alpha.popConfirm.title')}</div>
        <div
          style={
            {
              marginRight: 'auto'
            }
          }
        >
          <Button
            icon={<Icon icon="dataCloud/v2/icon_arrow_long_right" />}
            onClick={() => {
              setPopConfirmVisible(false);
              localStorage.setItem(popConfirmedKey, 'true');

              if (location.pathname !== '/dataCloud/v2') {
                nav('/dataCloud/v2');
              }
            }}
            className="w-[42px] h-[32px] active:!bg-[#FEFEFE]"
          />
        </div>
      </div>
    </div>
  );

  const renderContent = (): React.ReactElement => (
    <div className="w-[132px] flex flex-col">
      <RenderButton
        isNew
        active={version === 'v2'}
        onClick={() => {
          dispatch(dataCloudActions.produceToAlphaSwitch());
          nav('/dataCloud/v2');
        }}
      >
        Alpha
      </RenderButton>
    </div>
  );

  const renderSwitchButton = (): React.ReactElement => (
    <Button
      theme="solid"
      icon={<Icon icon="common/arrow-line" />}
      iconPosition="right"
      className={classNames('btn-switch', {
        hover: popoverVisible
      })}
      onClick={() => {
        setPopoverVisible(!popoverVisible);
      }}
    >
      {location.pathname.toLowerCase() === '/datacloud/v2' ? 'Alpha' : 'Classic'}
    </Button>
  );

  const renderOnboarding = () => (
    <Popover
      visible={popConfirmVisible}
      position="bottom"
      content={renderPopover()}
      trigger="custom"
      showArrow
      stopPropagation
      style={{
        backgroundColor: '#3089D2',
        boxShadow: '0px 2px 4px 0px #00000026',
        borderColor: '#3089D2',
        padding: 0,
        marginTop: '24px'
      }}
    >
      {renderSwitchButton()}
    </Popover>
  );

  if (popConfirmVisible) {
    return (
      <div className="version-switch">
        {renderOnboarding()}
      </div>
    );
  }
  return (
    <div className="version-switch">
      <Popover
        className={classNames('version-switch-popover p-0')}
        content={renderContent}
        ref={popoverRef}
        visible={popoverVisible}
        trigger="custom"
        showArrow
        style={
          {
            marginTop: '13px',
            backgroundColor: '#2E363A',
            borderColor: '#2E363A',
            boxShadow: '0px 4px 10px 0px #081117B2'
          }
        }
        onClickOutSide={() => {
          setPopoverVisible(false);
        }}
      >
        {renderSwitchButton()}
      </Popover>
    </div>
  );
}
